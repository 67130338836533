import styled, { CSSObject } from 'styled-components';
import { desktop } from 'utils/media';
import theme from 'utils/theme';

interface ButtonElProps {
  colorScheme: Sproutl.ColorScheme;
  disabled?: boolean;
  variant:
    | 'primary'
    | 'secondary'
    | 'secondaryFilled'
    | 'circle'
    | 'inline'
    | 'link'
    | 'white';
  width?: 'auto' | 'half' | 'full';
}

const defaultColors = {
  color: theme.colors.lightGreen,
  borderColor: theme.colors.darkGreen,
  backgroundColor: theme.colors.darkGreen,
};

const disabledColors = {
  color: theme.colors.black,
  borderColor: theme.colors.lightGrey,
  backgroundColor: theme.colors.lightGrey,
};

const variantDisabledStyles: Record<string, any> = {
  inline: {
    color: theme.colors.grey,
    borderColor: 'transparent',
    backgroundColor: 'transparent',
    pointerEvents: 'none',
    cursor: 'inherit',

    '&, &:hover, &:focus': {
      borderColor: 'transparent',
    },
  },
};

const primaryColorSchemes: Partial<
  Record<
    Sproutl.ColorScheme,
    { color: string; borderColor: string; backgroundColor: string }
  >
> = {
  pineGreen: {
    color: theme.colors.lightGreen,
    borderColor: theme.colors.darkGreen,
    backgroundColor: theme.colors.darkGreen,
  },
  irisPurple: {
    color: theme.colors.black,
    borderColor: theme.colors.purple,
    backgroundColor: theme.colors.purple,
  },
};

const secondaryColorSchemes = {
  blackTransparent: {
    color: theme.colors.black,
    borderColor: theme.colors.black,
    backgroundColor: 'transparent',
  },
  blackWhite: {
    color: theme.colors.black,
    borderColor: theme.colors.black,
    backgroundColor: theme.colors.white,
  },
  white: {
    color: theme.colors.black,
    borderColor: 'transparent',
    backgroundColor: theme.colors.white,
  },
  greenTextOnly: {
    color: theme.colors.darkGreen,
    border: 0,
    backgroundColor: 'transparent',
  },
};

const variantStyles = (
  colorScheme: Sproutl.ColorScheme,
): Record<string, CSSObject> => ({
  defaults: {
    margin: 0,
    fontSize: 16,
    borderWidth: 2,
    cursor: 'pointer',
    borderRadius: 100,
    alignItems: 'center',
    borderStyle: 'solid',
    display: 'inline-flex',
    justifyContent: 'center',

    // Properties which are sometimes overriden
    padding: '12px 24px',
    fontWeight: theme.weights.bold,
    height: theme.sizes.button.height,

    transition:
      'background-color ease-out 150ms, border-color ease-out 150ms, color ease-out 150ms, transform ease-out 150ms',

    '&:focus': {
      outline: 'none',
    },

    'svg + *': {
      marginLeft: '0.5em',
    },
  },

  primary: {
    ':hover, &:focus':
      colorScheme === 'pineGreen'
        ? {
            borderColor: theme.colors.coleusGreen,
            backgroundColor: theme.colors.coleusGreen,
            color: theme.colors.pineGreen,
          }
        : {},
  },

  secondary: {
    color: theme.colors[colorScheme],
    backgroundColor: 'transparent',
    borderColor: theme.colors[colorScheme],

    ':hover, &:focus': {
      borderColor: theme.colors[colorScheme],
      backgroundColor: theme.colors[colorScheme],
      color:
        colorScheme === 'pineGreen' ||
        colorScheme === 'black' ||
        colorScheme === 'thistleBlue'
          ? theme.colors.white
          : theme.colors.black,
    },
  },

  secondaryFilled: {
    ...secondaryColorSchemes.blackWhite,

    ':hover, &:focus': {
      borderColor: theme.colors.purple,
    },
  },

  white: {
    ...secondaryColorSchemes.white,

    ':hover, &:focus': {
      backgroundColor: theme.colors.black,
      color: theme.colors.white,
    },

    '&:focus-visible': {
      boxShadow: `0 0 0 2px ${theme.colors.purple}`,
    },
  },

  circle: {
    ...secondaryColorSchemes.blackTransparent,

    padding: '12px 11px',
    width: theme.sizes.button.height,

    ':hover, &:focus': {
      borderColor: theme.colors.purple,
    },
  },

  inline: {
    ...secondaryColorSchemes.greenTextOnly,

    padding: 0,
    height: 'auto',
    fontWeight: theme.weights.normal,

    ':hover': {
      color: theme.colors.black,
    },
  },

  link: {
    ...secondaryColorSchemes.greenTextOnly,

    padding: 0,
    height: 'auto',
    fontWeight: theme.weights.normal,

    textDecoration: 'underline',
  },

  disabled: {
    ...disabledColors,

    opacity: 0.5,
    cursor: 'inherit',
    pointerEvents: 'none',

    '&, &:hover, &:focus': {
      borderColor: theme.colors.lightGrey,
    },
  },
});

export const ButtonEl = styled.button<ButtonElProps>(
  ({ colorScheme, disabled, variant, width }) => {
    const variantStyle = variantStyles(colorScheme);

    return {
      ...defaultColors,

      // Get color scheme value or return defaults if undefined. This is for primary buttons only
      ...(colorScheme && colorScheme in primaryColorSchemes
        ? primaryColorSchemes[colorScheme]
        : {}),

      // Set the width which can be overridden by the variants
      width: width === 'full' ? '100%' : width === 'half' ? '100%' : 'auto',
      [desktop]: {
        width: width === 'full' ? '100%' : width === 'half' ? '50%' : 'auto',
      },

      // Get default styles
      ...variantStyle.defaults,

      // Get variant specific styles
      ...variantStyle[variant],

      '&:active': {
        transform: 'scale(0.95)',
      },

      // Override with disabled styles if needed
      ...(disabled
        ? variantDisabledStyles[variant] || variantStyle.disabled
        : {}),
    };
  },
);
