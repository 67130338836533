import React from 'react';

export default function Hamburger() {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 2H0V0h16v2zm0 6H0V6h16v2zM0 14h16v-2H0v2z"
        fill="currentColor"
      />
    </svg>
  );
}
