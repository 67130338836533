import React from 'react';

export default function Tick() {
  return (
    <svg
      width={10}
      height={8}
      viewBox="0 0 10 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.773 7.75L10 1.158 9.313.432 3.742 6.329.687 3.095 0 3.822l3.558 3.766.031-.033.184.194z"
        fill="currentColor"
      />
    </svg>
  );
}
