import React from 'react';

interface IMastercardProps {
  width?: number;
  height?: number;
}

function Mastercard({ height, width }: IMastercardProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      width={width}
      height={height}
      viewBox="0 0 36 24"
    >
      <path
        fill="#FF5F00"
        d="M21.756 4.895l-7.747.027.235 14.182 7.747-.026-.235-14.183z"
      />
      <path
        fill="#EB001B"
        d="M14.52 12.042a8.975 8.975 0 013.268-7.11 8.868 8.868 0 00-5.52-1.913c-4.918.017-8.83 4.07-8.748 9.06.083 4.992 4.13 9.017 9.049 9a8.657 8.657 0 005.454-1.95 9.2 9.2 0 01-3.504-7.087z"
      />
      <path
        fill="#F79E1B"
        d="M32.48 11.92c.083 4.992-3.83 9.044-8.748 9.06a8.867 8.867 0 01-5.52-1.912c2.05-1.663 3.317-4.226 3.27-7.11a9.237 9.237 0 00-3.505-7.087 8.658 8.658 0 015.455-1.95c4.919-.017 8.966 4.033 9.048 9z"
      />
    </svg>
  );
}

export default Mastercard;
