import React from 'react';

export default function Lock() {
  return (
    <svg width="14" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.606 6.704h-2.76V3.648C10.745 1.676 9.07 0 7 0 5.028 0 3.254 1.676 3.254 3.648v3.056H.493c-.296 0-.493.197-.493.493v10.254c0 .296.197.493.493.493h13.113c.295 0 .493-.197.493-.493V7.197c-.099-.296-.296-.493-.493-.493zM4.14 3.845s0-.099 0 0C4.14 2.17 5.423.887 7 .887a2.861 2.861 0 0 1 2.86 2.86v3.056H4.14V3.845zm8.972 3.747v9.366H.887V7.592h12.226z"
        fill="currentColor"
      />
      <path
        d="M7 14.197c.296 0 .493-.197.493-.493v-2.76c0-.296-.197-.493-.493-.493s-.493.197-.493.493v2.76c.099.296.296.493.493.493z"
        fill="currentColor"
      />
    </svg>
  );
}
