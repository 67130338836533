import React from 'react';

export default function YouTube() {
  return (
    <svg
      width={93.75}
      height={20}
      viewBox="0 0  93.75 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.2753 3.12206C29.1046 2.51683 28.7706 1.96503 28.3067 1.52178C27.8429 1.07854 27.2654 0.759379 26.6321 0.596192C24.2989 -0.0105815 14.949 -0.010582 14.949 -0.010582C14.949 -0.010582 5.59665 -0.0105817 3.2684 0.586784C2.63364 0.750352 2.05514 1.07078 1.59113 1.51584C1.12711 1.9609 0.793939 2.51488 0.62513 3.12206C-1.64299e-06 5.34925 0 9.99882 0 9.99882C0 9.99882 -1.64299e-06 14.6484 0.62513 16.8756C0.795448 17.481 1.12929 18.0331 1.59321 18.4764C2.05713 18.9197 2.63482 19.2387 3.2684 19.4015C5.59911 19.9988 14.949 19.9988 14.949 19.9988C14.949 19.9988 24.3014 19.9988 26.6321 19.4015C27.2654 19.2383 27.8429 18.9191 28.3067 18.4759C28.7706 18.0326 29.1046 17.4808 29.2753 16.8756C29.898 14.6484 29.898 9.99882 29.898 9.99882C29.898 9.99882 29.8906 5.34925 29.2753 3.12206ZM11.9587 14.2839V5.71378L19.7261 9.99882L11.9587 14.2839Z"
        fill="currentColor"
      />
      <path
        d="M43.3997 18.1938C42.7707 17.7638 42.3229 17.1333 42.1372 16.4158C41.8576 15.369 41.7317 14.2902 41.7631 13.2103V11.3899C41.7293 10.2923 41.8754 9.19646 42.1962 8.14205C42.4096 7.4154 42.8824 6.78304 43.5326 6.35466C44.2534 5.95164 45.0808 5.75643 45.915 5.79257C46.8847 5.79257 47.6616 5.98307 48.2457 6.36406C48.8729 6.80415 49.3257 7.43413 49.5305 8.15146C49.8327 9.20582 49.9695 10.2972 49.9365 11.3899V13.2103C49.9688 14.2954 49.8353 15.3792 49.5403 16.4276C49.3444 17.1456 48.8905 17.7749 48.258 18.2055C47.6641 18.5787 46.8618 18.7661 45.851 18.7676C44.8075 18.7676 43.9904 18.5763 43.3997 18.1938ZM46.7124 16.2324C46.8765 15.8263 46.9586 15.1591 46.9586 14.2309V10.3387C46.9586 9.43791 46.8773 8.78175 46.7124 8.36547C46.661 8.18437 46.548 8.02478 46.3911 7.91192C46.2342 7.79906 46.0425 7.73936 45.8461 7.74224C45.653 7.74204 45.4653 7.80315 45.3125 7.91596C45.1597 8.02877 45.0505 8.18689 45.0019 8.36547C44.837 8.78175 44.7558 9.43791 44.7558 10.3387V14.2404C44.7558 15.167 44.8379 15.8341 45.0019 16.2418C45.0504 16.4214 45.1613 16.58 45.3165 16.6916C45.4716 16.8031 45.6619 16.8609 45.856 16.8556C46.0508 16.8572 46.2407 16.7968 46.3958 16.684C46.5508 16.5712 46.6622 16.4123 46.7124 16.2324Z"
        fill="currentColor"
      />
      <path
        d="M88.8129 13.2385V13.8664C88.8038 14.4693 88.8284 15.0722 88.8867 15.6726C88.9032 15.9875 89.0105 16.2919 89.1968 16.5522C89.2877 16.6482 89.4004 16.7229 89.5263 16.7706C89.6522 16.8183 89.7879 16.8378 89.9229 16.8274C90.1087 16.8443 90.2956 16.8065 90.4583 16.7191C90.6211 16.6316 90.752 16.4987 90.8335 16.3382C90.9975 16.0121 91.0862 15.4672 91.0993 14.7037L93.6441 14.8471C93.661 14.9963 93.6684 15.1463 93.6663 15.2963C93.6663 16.4534 93.3381 17.3158 92.6818 17.8834C92.0173 18.4548 91.082 18.7418 89.8687 18.7418C88.4166 18.7418 87.3952 18.3067 86.812 17.4341C86.2287 16.5616 85.9358 15.214 85.9358 13.389V11.2018C85.9358 9.32032 86.2385 7.94842 86.844 7.08608C87.4494 6.22374 88.4823 5.79571 89.9425 5.80198C90.9549 5.80198 91.7318 5.97915 92.2732 6.33349C92.8481 6.73412 93.2541 7.31807 93.4177 7.97978C93.6692 8.99714 93.7801 10.0416 93.7475 11.0865V13.2314L88.8129 13.2385ZM89.187 7.96802C89.0062 8.22584 88.9024 8.52587 88.8867 8.83584C88.8282 9.44407 88.8035 10.0548 88.8129 10.6656V11.564H90.9713V10.6656C90.9809 10.0546 90.9522 9.44356 90.8852 8.83584C90.8665 8.52132 90.7594 8.21743 90.5751 7.95626C90.4867 7.86696 90.3784 7.79778 90.2583 7.75381C90.1381 7.70985 90.0092 7.69223 89.881 7.70226C89.7517 7.6921 89.6217 7.71082 89.5012 7.75695C89.3807 7.80307 89.2732 7.87533 89.187 7.96802Z"
        fill="currentColor"
      />
      <path
        d="M36.2133 13.0033L32.8563 1.4205H35.7974L36.9738 6.6698C37.2741 7.96488 37.4948 9.06867 37.6359 9.98118H37.722C37.8221 9.32737 38.0436 8.22985 38.3865 6.68861L39.6048 1.41815H42.5336L39.1347 13.0009V18.5701H36.2133V13.0033Z"
        fill="currentColor"
      />
      <path
        d="M59.4589 6.03716V18.5701H57.1478L56.9017 17.0367H56.8377C56.211 18.1938 55.27 18.7731 54.0148 18.7747C53.1452 18.7747 52.5037 18.5019 52.0902 17.9562C51.6767 17.4106 51.47 16.5593 51.47 15.4022V6.03716H54.4233V15.2328C54.3963 15.6396 54.4618 16.0472 54.6153 16.4276C54.6734 16.5411 54.7655 16.6357 54.8798 16.6993C54.9941 16.763 55.1256 16.7928 55.2577 16.785C55.523 16.7798 55.7805 16.6978 55.996 16.5499C56.2228 16.4094 56.4042 16.2116 56.5202 15.9784V6.03716H59.4589Z"
        fill="currentColor"
      />
      <path
        d="M74.5923 6.03716V18.5701H72.2838L72.0377 17.0367H71.9762C71.3494 18.1938 70.4084 18.7731 69.1532 18.7747C68.282 18.7747 67.6421 18.5019 67.2286 17.9562C66.8151 17.4106 66.6084 16.5593 66.6084 15.4022V6.03716H69.5618V15.2328C69.5347 15.6396 69.6002 16.0472 69.7537 16.4276C69.8116 16.5413 69.9036 16.6361 70.018 16.6997C70.1324 16.7634 70.264 16.7931 70.3961 16.785C70.6616 16.7806 70.9192 16.6985 71.1344 16.5499C71.3624 16.4108 71.5442 16.2125 71.6587 15.9784V6.03716H74.5923Z"
        fill="currentColor"
      />
      <path
        d="M67.4749 3.68768H64.5462V18.5701H61.6592V3.68768H58.7305V1.42051H67.4749V3.68768Z"
        fill="currentColor"
      />
      <path
        d="M84.3583 8.03857C84.1811 7.25071 83.8906 6.67686 83.4919 6.32409C83.0359 5.94983 82.4456 5.75928 81.8454 5.79257C81.3048 5.79209 80.7763 5.94517 80.3269 6.23237C79.8638 6.5183 79.4878 6.91578 79.2366 7.38476H79.2169V0.785515H76.3718V18.5701H78.8108L79.1086 17.3942H79.1726C79.4033 17.8173 79.7606 18.1652 80.1989 18.3937C80.6625 18.6419 81.1861 18.7692 81.7174 18.7629C82.1804 18.7779 82.6375 18.6603 83.03 18.4252C83.4225 18.1901 83.7325 17.8483 83.9202 17.4436C84.3911 16.5671 84.6265 15.1952 84.6265 13.3279V11.3453C84.6534 10.2372 84.5636 9.12941 84.3583 8.03857ZM81.651 13.1491C81.6627 13.8658 81.6232 14.5824 81.5329 15.294C81.5014 15.6876 81.3659 16.0671 81.1391 16.397C81.0507 16.5022 80.9381 16.5864 80.81 16.6431C80.6819 16.6999 80.5419 16.7275 80.4007 16.7239C80.1651 16.7258 79.933 16.6699 79.7264 16.5616C79.5175 16.4449 79.347 16.2746 79.2341 16.0701V8.96049C79.3197 8.64124 79.497 8.35122 79.7461 8.12324C79.955 7.91897 80.2397 7.80153 80.5386 7.79634C80.6747 7.79085 80.81 7.8189 80.9316 7.87779C81.0531 7.93668 81.1568 8.02445 81.2326 8.13265C81.4365 8.48029 81.5536 8.86824 81.5747 9.26623C81.649 10.0198 81.6811 10.7766 81.6707 11.5334L81.651 13.1491Z"
        fill="currentColor"
      />
    </svg>
  );
}
