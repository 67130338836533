import SdkAuth, { TokenProvider } from '@commercetools/sdk-auth';
import jsCookie from 'js-cookie';
import SsCookie from 'cookies';
import * as Sentry from '@sentry/nextjs';
import { RequestContext } from '../types';
import { TokenInfo } from './types';
import { useEffect, useState } from 'react';

export const CT_TOKEN_COOKIE_NAME = 'sprtl.ct.token';

/**
 * Checks if a commercetools token cookie exists
 * @returns {boolean}
 */
export function hasAnonymousToken(): boolean {
  return !!(
    typeof window !== 'undefined' && jsCookie.get(CT_TOKEN_COOKIE_NAME)
  );
}

/**
 * Creates a new commercetools auth client and token provider then gets an
 * updated anonymous token
 * @param {object | undefined} existingToken
 * @returns {object} Token object
 */
async function fetchToken(
  existingToken: Record<string, unknown> | undefined,
): Promise<TokenInfo> {
  const authClient = new SdkAuth({
    host: 'https://auth.commercetools.com',
    projectKey: process.env.NEXT_PUBLIC_COMMERCETOOLS_PROJECT_KEY,
    disableRefreshToken: false,
    credentials: {
      clientId: process.env.NEXT_PUBLIC_COMMERCETOOLS_CLIENT_ID,
      clientSecret: process.env.NEXT_PUBLIC_COMMERCETOOLS_CLIENT_SECRET,
    },
    scopes: [
      `view_products:${process.env.NEXT_PUBLIC_COMMERCETOOLS_PROJECT_KEY}`,
      `manage_my_orders:${process.env.NEXT_PUBLIC_COMMERCETOOLS_PROJECT_KEY}`,
      `manage_my_payments:${process.env.NEXT_PUBLIC_COMMERCETOOLS_PROJECT_KEY}`,
    ],
  });

  const tokenProvider = new TokenProvider(
    {
      sdkAuth: authClient,
      fetchTokenInfo: (sdkAuth: any) => sdkAuth.anonymousFlow(),
      // onTokenInfoChanged: () => console.log('Token changed!'),
    },
    existingToken,
  );

  const token: TokenInfo = await tokenProvider.getTokenInfo();

  return token;
}

/**
 * Get the commercetools token object from the browser cookie
 * @returns {object} Token object
 */
export async function getAnonymousToken() {
  const tokenCookieValue = jsCookie.get(CT_TOKEN_COOKIE_NAME);

  let existingToken;
  try {
    existingToken = tokenCookieValue ? JSON.parse(tokenCookieValue) : null;
  } catch (e) {
    Sentry.captureException(e);
    console.error('getAnonymousToken: failed to parse cookie JSON');
  }

  const token = await fetchToken(existingToken);

  jsCookie.set(CT_TOKEN_COOKIE_NAME, JSON.stringify(token), {
    path: '/',
    expires: 365,
    secure: !!process.env.NEXT_PUBLIC_VERCEL_URL,
  });

  return token;
}

/**
 * Get the commercetools access token from the browser cookie
 * This is intended to be run client-side
 * @returns {string | undefined} Token
 */
export async function getAnonymousAccessToken() {
  const token = await getAnonymousToken();

  return token.access_token;
}

/**
 * Get the commercetools token object from the cookie inside the context object
 * This is intended to be used inside getServerSideProps or in an API endpoint
 * @param {RequestContext} ctx Object containing the request and response objects
 * @returns {object} Token object
 */
export async function getAnonymousTokenFromContext({
  req,
  res,
}: RequestContext) {
  const ssCookie = new SsCookie(req, res, {
    secure: !!process.env.NEXT_PUBLIC_VERCEL_URL,
  });

  const tokenCookieValue = ssCookie.get(CT_TOKEN_COOKIE_NAME);

  let existingToken;
  try {
    existingToken = tokenCookieValue
      ? JSON.parse(decodeURIComponent(tokenCookieValue))
      : null;
  } catch (e) {
    Sentry.captureException(e);
    console.error('getAnonymousTokenFromContext: failed to parse cookie JSON');
  }

  const token = await fetchToken(existingToken);

  const oneYearFromNow = new Date();
  oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

  ssCookie.set(CT_TOKEN_COOKIE_NAME, JSON.stringify(token), {
    path: '/',
    expires: oneYearFromNow,
    httpOnly: false,
    secure: !!process.env.NEXT_PUBLIC_VERCEL_URL,
  });

  return token;
}

/**
 * Get the commercetools access token from the cookie inside the context object
 * This is intended to be used inside getServerSideProps or in an API endpoint
 * @param {RequestContext} ctx Object containing the request and response objects
 * @returns {string} Token
 */
export async function getAnonymousAccessTokenFromContext(ctx: RequestContext) {
  const token = await getAnonymousTokenFromContext(ctx);

  return token.access_token;
}

export function destroyAnonymousTokenFromContext(ctx: RequestContext) {
  const ssCookie = new SsCookie(ctx.req, ctx.res, {
    secure: !!process.env.NEXT_PUBLIC_VERCEL_URL,
  });

  // Delete the existing cookie
  return ssCookie.set(CT_TOKEN_COOKIE_NAME);
}

export function useHasAnonymousToken() {
  const defaultValue =
    typeof window === 'undefined'
      ? false
      : !!jsCookie.get(CT_TOKEN_COOKIE_NAME);
  const [state, setState] = useState(defaultValue);

  useEffect(() => {
    const interval = window.setInterval(() => {
      setState(!!jsCookie.get(CT_TOKEN_COOKIE_NAME));
    }, 250);

    return () => {
      window.clearInterval(interval);
    };
  }, []);

  return state;
}
