import React from 'react';

export default function LinkedIn() {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.522 0H1.47864C0.66258 0 0.000732422 0.641847 0.000732422 1.43261V18.5678C0.000732422 19.3586 0.662901 20 1.47864 20H18.5228C19.3392 20 20.0007 19.3586 20.0007 18.5675V1.43261C20.0001 0.641847 19.3384 0 18.522 0ZM3.08507 16.7373V7.73333H6.07784V16.7373H3.08507ZM4.5821 6.50442H4.56242C3.558 6.50442 2.9086 5.81301 2.9086 4.949C2.9086 4.06546 3.5776 3.39317 4.60194 3.39317C5.62627 3.39317 6.25559 4.0653 6.27511 4.94908C6.27519 5.81277 5.62555 6.5041 4.58218 6.5041L4.5821 6.50442ZM16.8697 16.7373H13.8773V11.9209C13.8773 10.7104 13.4439 9.88474 12.3613 9.88474C11.534 9.88474 11.0419 10.4415 10.8256 10.9794C10.7466 11.1714 10.7271 11.4409 10.7271 11.7096V16.7377H7.73447C7.73447 16.7377 7.77382 8.57823 7.73447 7.73365H10.7272V9.00819C11.125 8.39478 11.837 7.52225 13.4244 7.52225C15.3934 7.52225 16.8696 8.80924 16.8696 11.5748L16.8697 16.7373Z"
        fill="currentColor"
      />
    </svg>
  );
}
