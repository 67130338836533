import React from 'react';

export default function Profile() {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.905 28.448A11.238 11.238 0 0117 22.159c4.427 0 8.255 2.563 10.088 6.278"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeMiterlimit="10"
      />
      <path d="M17 19a5 5 0 100-10 5 5 0 000 10z" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 3.25C9.406 3.25 3.25 9.406 3.25 17S9.406 30.75 17 30.75 30.75 24.594 30.75 17 24.594 3.25 17 3.25zM.75 17C.75 8.025 8.025.75 17 .75S33.25 8.025 33.25 17 25.975 33.25 17 33.25.75 25.975.75 17z"
        fill="currentColor"
      />
    </svg>
  );
}
