import jsCookie from 'js-cookie';
import { useRouter } from 'next/router';

export function useFeatureFlag(key: string) {
  const router = useRouter();
  const featureKey = `feature_${key}`;

  if (typeof window === 'undefined') {
    return false;
  }

  if (featureKey in router.query) {
    if (
      router.query[featureKey] === 'off' ||
      router.query[featureKey] === 'false' ||
      router.query[featureKey] === 'hide' ||
      router.query[featureKey] === '0'
    ) {
      jsCookie.remove(featureKey);
      return false;
    } else {
      jsCookie.set(featureKey, 'enabled', {
        path: '/',
        expires: 365,
      });

      return true;
    }
  }

  return !!jsCookie.get(featureKey);
}
