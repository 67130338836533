import React from 'react';

interface IAmexProps {
  width?: number;
  height?: number;
}

function Amex({ height, width }: IAmexProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      width={width}
      height={height}
      viewBox="0 0 31 31"
    >
      <path
        fill="#26A6D1"
        d="M1.937 4.36h27.125c1.07 0 1.938.867 1.938 1.938v18.406c0 1.07-.868 1.938-1.938 1.938H1.938A1.937 1.937 0 010 24.704V6.298c0-1.07.868-1.939 1.937-1.939z"
      />
      <path
        fill="#fff"
        d="M4.999 12.11l-3.061 6.774h3.664l.455-1.08h1.038l.455 1.08h4.033v-.825l.36.825h2.087l.359-.842v.842h8.39l1.02-1.052.955 1.052 4.309.009-3.071-3.378 3.07-3.406H24.82l-.992 1.033-.926-1.033h-9.126l-.784 1.749-.802-1.749H8.533v.797l-.407-.797H5zm.709.961h1.786l2.031 4.594v-4.594h1.957l1.568 3.294 1.446-3.294h1.947v4.862h-1.185l-.01-3.81-1.727 3.81h-1.06l-1.737-3.81v3.81H8.287l-.462-1.09H5.328l-.46 1.089H3.56l2.147-4.86zm11.819 0h4.817l1.474 1.592 1.52-1.592h1.474l-2.239 2.444 2.24 2.415h-1.541l-1.473-1.61-1.53 1.61h-4.742V13.07zm-10.95.823l-.822 1.941h1.644l-.822-1.941zm12.14.184v.887h2.628v.99h-2.629v.968h2.948l1.37-1.427-1.311-1.419h-3.007z"
      />
    </svg>
  );
}

export default Amex;
