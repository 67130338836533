import React from 'react';

export default function Instagram() {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.042 0H5.958A5.965 5.965 0 000 5.958v8.084A5.965 5.965 0 005.958 20h8.084A5.965 5.965 0 0020 14.042V5.958A5.965 5.965 0 0014.042 0zm3.946 14.042a3.946 3.946 0 01-3.946 3.946H5.958a3.946 3.946 0 01-3.946-3.946V5.958a3.946 3.946 0 013.946-3.946h8.084a3.946 3.946 0 013.946 3.946v8.084zM10 4.828A5.178 5.178 0 004.827 10 5.178 5.178 0 0010 15.172 5.178 5.178 0 0015.173 10 5.178 5.178 0 0010 4.828zm0 8.333a3.16 3.16 0 110-6.321 3.16 3.16 0 010 6.32zm6.422-8.294a1.24 1.24 0 11-2.479 0 1.24 1.24 0 012.48 0z"
        fill="currentColor"
      />
    </svg>
  );
}
