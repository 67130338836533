import React from 'react';

interface RemoveProps {
  width?: number;
  height?: number;
}

export default function Remove({ width = 10, height = 10 }: RemoveProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill="currentColor" d="M10 4.166v1.667H0V4.166z" />
    </svg>
  );
}
