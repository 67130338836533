import React from 'react';

export default function Location() {
  return (
    <svg
      width={14}
      height={20}
      viewBox="0 0 14 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#prefix__clip0)">
        <path
          d="M11.438 2.287A6.412 6.412 0 009.366.727a5.938 5.938 0 00-5.078.008 6.506 6.506 0 00-2.072 1.56C1.003 3.655.327 5.468.327 7.395c0 1.838.815 4.104 2.418 6.736 1.265 2.075 2.687 3.826 3.415 4.698a.837.837 0 001.326.009c.736-.872 2.166-2.624 3.423-4.698 1.603-2.632 2.418-4.907 2.418-6.737 0-1.935-.667-3.748-1.89-5.116zM6.828 10.4c-1.674 0-3.034-1.386-3.034-3.094s1.36-3.094 3.033-3.094C8.5 4.222 9.86 5.607 9.86 7.307c0 1.708-1.36 3.094-3.033 3.094z"
          fill="#000"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" transform="translate(.327 .16)" d="M0 0h13v19H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
