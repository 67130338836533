// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { withoutGraphQLFetch } from 'apollo-link-sentry';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

function isPageDataRequest(breadcrumb) {
  try {
    return (
      breadcrumb.category === 'fetch' &&
      breadcrumb.data.url.startsWith('/_next/data/')
    );
  } catch (e) {
    return false;
  }
}

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://5fb5000518604e5ca62a49b4ba69fdb0@o739159.ingest.sentry.io/5785595',
  environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
  enabled: !!process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
  tracesSampleRate: 0.25,
  beforeBreadcrumb: withoutGraphQLFetch((breadcrumb, hint) => {
    if (isPageDataRequest(breadcrumb)) {
      return null;
    }

    if (breadcrumb.category === 'ui.click') {
      return {
        ...breadcrumb,
        message: `${hint.event.target.tagName.toLowerCase()}: ${
          hint.event.target.ariaLabel || hint.event.target.innerText
        }`,
      };
    }

    return breadcrumb;
  }),
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
