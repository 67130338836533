import { CartDataFragmentDoc } from 'generated/api/graphql';

import { initializeApollo } from 'utils/apollo-client';
import { IGroupedCart } from 'utils/commercetools/types';

export const UpdateCartShippingFragment = (cartData: IGroupedCart) => {
  const apolloClient = initializeApollo();
  apolloClient.writeFragment({
    id: `Cart:${cartData.id}`,
    fragment: CartDataFragmentDoc,
    fragmentName: 'CartData',
    data: {
      id: cartData.id,
      version: cartData.version,
      shippingAddress: {
        __typename: 'Address',
        ...cartData?.shippingAddress,
      },
      totalPrice: {
        __typename: 'Money',
        ...cartData.totalPrice,
      },
      custom: {
        __typename: 'CustomFieldsType',
        ...cartData.custom,
      },
      shippingInfo: {
        __typename: 'ShippingInfo',
        ...cartData.shippingInfo,
        price: {
          __typename: 'Money',
          ...cartData.shippingInfo?.price,
        },
        shippingRate: {
          __typename: 'ShippingRate',
          ...cartData.shippingInfo?.shippingRate,
          price: {
            __typename: 'Money',
            ...cartData.shippingInfo?.shippingRate.price,
          },
        },
      },
    },
  });
};
