import React from 'react';

interface ICloseProps {
  width?: number;
  height?: number;
}

function Close({ width = 16, height = 16 }: ICloseProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6 14.4l-12-12 1.018-1.018 12 12L13.6 14.4z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.457 2.4l-12 12-1.018-1.018 12-12L14.457 2.4z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Close;
