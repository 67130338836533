import React from 'react';

interface ILeftArrowProps {
  height?: number;
  width?: number;
}

const LeftArrow = ({ height = 17, width = 20 }: ILeftArrowProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.434 8.413c2.825 1.628 4.752 4.771 4.752 8.413H7.979c0-3.959-3.085-7.227-6.959-7.227H0V7.227h1.02C4.894 7.227 7.98 3.96 7.98 0h2.207c0 3.641-1.927 6.785-4.752 8.413z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 9.599H2.915V7.227H20V9.6z"
      fill="currentColor"
    />
  </svg>
);

export default LeftArrow;
