import React from 'react';

export default function Twitter() {
  return (
    <svg
      width={24}
      height={20}
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.543 4.979c.015.217.015.434.015.653C21.558 12.305 16.605 20 7.548 20v-.004A13.686 13.686 0 010 17.732c.389.048.78.072 1.172.073a9.731 9.731 0 006.115-2.166c-2.107-.04-3.954-1.45-4.6-3.506a4.79 4.79 0 002.223-.087C2.613 11.57.96 9.5.96 7.096v-.064a4.8 4.8 0 002.235.632C1.032 6.18.365 3.229 1.671.92c2.5 3.155 6.189 5.073 10.148 5.276a5.136 5.136 0 011.425-4.825 4.843 4.843 0 016.966.22A9.732 9.732 0 0023.337.364a5.049 5.049 0 01-2.165 2.793A9.603 9.603 0 0024 2.363a10.15 10.15 0 01-2.457 2.616z"
        fill="currentColor"
      />
    </svg>
  );
}
