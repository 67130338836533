import React from 'react';

const TrustPilotText = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      See our reviews on
      <a
        className="trustpilot-logo"
        href="https://uk.trustpilot.com/review/sproutl.com"
        target="blank"
        rel="noreferrer noopner"
        aria-label="TrustPilot"
      >
        {children}
      </a>
    </>
  );
};

export default TrustPilotText;
