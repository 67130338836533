import React from 'react';

interface IVisaProps {
  width?: number;
  height?: number;
}

function Visa({ height, width }: IVisaProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      width={width}
      height={height}
      viewBox="0 0 37 24"
    >
      <path
        fill="#3362AB"
        d="M31.843 7.526h-1.938c-.586 0-1.037.176-1.307.746L24.9 16.474h2.615s.45-1.097.54-1.36h3.201c.09.307.316 1.316.316 1.316h2.344l-2.074-8.904zm-3.065 5.746c.225-.526.992-2.5.992-2.5 0 .044.225-.526.315-.833l.18.79s.496 2.148.587 2.587h-2.074v-.044zM25.082 13.535c0 1.842-1.713 3.07-4.373 3.07-1.127 0-2.208-.22-2.794-.482l.36-2.018.316.132c.811.35 1.352.482 2.344.482.721 0 1.487-.263 1.487-.877 0-.395-.315-.658-1.307-1.097-.947-.438-2.209-1.14-2.209-2.412 0-1.754 1.758-2.938 4.238-2.938.946 0 1.758.175 2.254.394l-.361 1.93-.18-.175a4.96 4.96 0 00-1.894-.351c-.946.044-1.397.438-1.397.79 0 .394.54.701 1.397 1.096 1.443.658 2.119 1.403 2.119 2.456z"
      />
      <path
        fill="#F9B50B"
        d="M3.083 7.614l.046-.176h3.876c.541 0 .947.176 1.082.746l.857 3.948c-.857-2.106-2.84-3.816-5.86-4.518z"
      />
      <path
        fill="#3362AB"
        d="M14.398 7.526l-3.922 8.904h-2.66L5.564 8.974c1.623 1.008 2.975 2.587 3.47 3.684l.271.92 2.434-6.096h2.66v.044zM15.435 7.482h2.48l-1.578 8.948h-2.48l1.578-8.948z"
      />
    </svg>
  );
}

export default Visa;
