const POSTCODE_BLACKLIST = [
  'IM',
  'GY',
  'JE',
  'PO',
  'TR',
  'BT',
  'IV',
  'HS',
  'ZE',
  'AB',
  'KA',
  'KW',
  'PA',
  'PH',
];

const postcodeRegex = /^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/i;

const nonNumberPlusRegex = /[^\d+]/g;
const alphabetRegex = /[a-z]/i;
const ukMobileNumberRegex = /^((\+|00)440?7\d{3}|07\d{3})\d{6}$/;

export const isPostcodeBlacklisted = (
  postCode: string | undefined | null,
): boolean => {
  if (!postCode) {
    return false;
  }

  return POSTCODE_BLACKLIST.some(
    (v) => postCode.toUpperCase().slice(0, v.length) === v,
  );
};

export const isValidPostcode = (postcode: string | undefined): boolean =>
  postcodeRegex.test(postcode || '');

export const validatePhoneNumber = (
  phoneNumber: string | undefined,
): string | undefined => {
  if (!phoneNumber) {
    return 'Mobile number is required';
  }

  let cleanPhoneNumber = String(phoneNumber).trim();

  if (alphabetRegex.test(cleanPhoneNumber)) {
    return 'Please enter a valid mobile number';
  }

  cleanPhoneNumber = cleanPhoneNumber.replace(nonNumberPlusRegex, '');

  if (!ukMobileNumberRegex.test(cleanPhoneNumber)) {
    return 'Please enter a valid mobile number';
  }
};
