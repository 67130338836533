import styled from 'styled-components';
import { desktop, largeDesktop } from 'utils/media';
import theme from 'utils/theme';
import { IconButton } from 'components/global/Header/styles';
import { motion } from 'framer-motion';

export const SearchIconLabel = styled(IconButton)({
  fontSize: 0,

  [largeDesktop]: {
    border: `1px solid ${theme.colors.lightGrey}`,
    background: theme.colors.lightestGrey,
    fontSize: 16,
    height: theme.sizes.input.height,
    width: 135,
    borderRadius: 40,
    color: theme.colors.midGrey,
    margin: '0 24px 0 0',
    position: 'relative',
    cursor: 'text',

    svg: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      left: 15,
      color: theme.colors.black,
      height: 20,
    },
  },
});

export const SearchOverlay = styled(motion.div)({
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: theme.zIndexes.header,

  ':before': {
    content: "''",
    display: 'block',
    backgroundColor: theme.colors.white,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,

    [desktop]: {
      backgroundColor: theme.colors.modalBackground,
    },
  },

  [desktop]: {
    display: 'block',
    position: 'fixed',
    width: '100%',
  },
});

export const SearchLogoWrapper = styled.div({
  display: 'none',
  paddingLeft: 20,
});

export const SearchHeaderInner = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 6px 0 24px',

  [desktop]: {
    padding: 0,
  },
});

export const SearchForm = styled.form({
  flexGrow: 1,
  position: 'relative',
  padding: '8px 0',
  height: 52,

  svg: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: 15,
    width: 16,
    height: 16,
  },

  [desktop]: {
    svg: {
      height: 20,
      width: 20,
    },
  },
});

export const SearchHeaderWrapper = styled.div({
  background: theme.colors.white,
  position: 'relative',
  paddingTop: 20,

  [desktop]: {
    background: theme.colors.white,
    height: 136,
    paddingTop: 36,

    [SearchHeaderInner]: {
      maxWidth: 1320,
      margin: '0 auto',
    },

    [SearchLogoWrapper]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.colors.irisPurple,

      svg: {
        height: 30,
        width: 'auto',
      },
    },

    [SearchForm]: {
      maxWidth: 440,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
});

export const Input = styled.input({
  width: '100%',
  height: 36,
  lineHeight: 36,
  borderRadius: 40,
  border: `1px solid ${theme.colors.lightGrey}`,
  background: theme.colors.lightestGrey,
  padding: '0 0 0 40px',
  appearance: 'none',

  '&:focus': {
    outline: 'none',
  },
  '&::placeholder': {
    color: theme.colors.midGrey,
    opacity: 1,
  },

  [desktop]: {
    height: theme.sizes.input.height,
    lineHeight: `${theme.sizes.input.height}px`,
  },
});
