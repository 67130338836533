import React from 'react';

export default function Help() {
  return (
    <svg
      width={20}
      height={21}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={10} cy={10.962} r={10} fill="currentColor" />
      <path
        d="M9.312 13.994c-.928 0-1.488.416-1.488 1.104 0 .672.528 1.072 1.408 1.072.88 0 1.456-.416 1.456-1.056 0-.688-.528-1.12-1.376-1.12zM6.64 9.306c.432-.624.544-.768.752-.96.48-.464 1.28-.768 2.032-.768.864 0 1.472.384 1.472.896 0 .304-.16.544-.64.992l-.96.88c-.992.912-1.04 1.008-1.04 2.048 0 .336 0 .544.032 1.024h1.968v-.48c0-.672.128-.976.512-1.296l1.424-1.152c.768-.624 1.168-1.408 1.168-2.336 0-1.792-1.424-3.024-3.472-3.024-1.36 0-2.352.464-3.248 1.536v2.64z"
        fill="#fff"
      />
    </svg>
  );
}
