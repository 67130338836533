import styled from 'styled-components';
import { largeDesktop, tablet } from 'utils/media';
import theme from 'utils/theme';

interface ContainerProps {
  noGutter?: boolean;
  noPadding?: boolean;
  inset?: boolean;
}

const Container = styled.div<ContainerProps>((props) => ({
  position: 'relative',
  maxWidth: '100%',
  margin: '0 auto',
  ...(props.noGutter
    ? {
        width: theme.pageWidth.extended,
      }
    : {
        width: props.inset ? theme.pageWidth.inset : theme.pageWidth.standard,
        padding: props.noPadding ? 0 : '0 20px',
      }),

  [tablet]: {
    ...(!props.noGutter && !props.noPadding
      ? {
          padding: '0 40px',
        }
      : null),
  },

  // This is used when the screen is larger than 1400 but the container is less
  // than 1320, it removes the additional 80px padding to give it more space
  [largeDesktop]: {
    ...(!props.noGutter && !props.noPadding
      ? {
          padding: 0,
        }
      : null),
  },
}));

export default Container;
