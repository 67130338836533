import React from 'react';

interface AddProps {
  width?: number;
  height?: number;
}

export default function Add({ width = 10, height = 10 }: AddProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill="currentColor" d="M4.167 0h1.667v10H4.167z" />
      <path fill="currentColor" d="M10 4.167v1.667H0V4.167z" />
    </svg>
  );
}
