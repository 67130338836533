import React from 'react';

interface IRightArrowProps {
  height?: number;
  width?: number;
}

const RightArrow = ({ width = 12, height = 9 }: IRightArrowProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.574 4.434C6.974 3.576 5.884 1.92 5.884 0h1.25c0 2.087 1.746 3.81 3.94 3.81h.578v1.25h-.578c-2.194 0-3.94 1.722-3.94 3.809h-1.25c0-1.92 1.09-3.577 2.69-4.435z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.326 3.81H10v1.25H.326V3.81z"
      fill="currentColor"
    />
  </svg>
);

export default RightArrow;
