import jsCookie from 'js-cookie';
import { useEffect, useState } from 'react';

const COOKIE_NAME = 'sprtl.cookie-banner';
const COOKIE_VALUE = 'accepted';
const COOKIE_MAXAGE = 365; // one year

export const cookieAccepted = () => jsCookie.get(COOKIE_NAME) === COOKIE_VALUE;

export const setCookieAccepted = () =>
  jsCookie.set(COOKIE_NAME, COOKIE_VALUE, {
    path: '/',
    expires: COOKIE_MAXAGE,
  });

export const useCookieAccepted = () => {
  const [internalState, setInternalState] = useState(cookieAccepted());

  useEffect(() => {
    const id = setInterval(() => {
      setInternalState(cookieAccepted());
    }, 200);

    return () => clearInterval(id);
  }, []);

  return internalState;
};
