import React from 'react';

export default function Filter() {
  return (
    <svg
      width={24}
      height={12}
      viewBox="0 0 24 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={15} cy={2.727} r={2} fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.313 10a.9.9 0 01-.9.9h-6.325a.9.9 0 110-1.8h6.325a.9.9 0 01.9.9zM23.313 2.727a.9.9 0 01-.9.9H20.15a.9.9 0 110-1.8h2.263a.9.9 0 01.9.9z"
        fill="currentColor"
      />
      <circle cx={9} cy={10} r={2} fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.125 10a.9.9 0 01-.9.9H.9a.9.9 0 110-1.8h6.325a.9.9 0 01.9.9zM16.25 2.727a.9.9 0 01-.9.9H.9a.9.9 0 010-1.8h14.45a.9.9 0 01.9.9z"
        fill="currentColor"
      />
    </svg>
  );
}
