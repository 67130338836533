import Logo from 'components/Logo';
import styled from 'styled-components';
import { desktop, mobile, tablet } from 'utils/media';
import theme from 'utils/theme';
import Container from 'components/layout/Container';

export const Wrapper = styled.footer({
  position: 'relative',
  overflow: 'hidden',
  background: theme.colors.offBlack,
  color: theme.colors.white,
  lineHeight: 1.5,
  '-webkit-font-smoothing': 'antialiased',
  a: {
    color: theme.colors.lightGreen,
    display: 'inline-block',
    margin: '4px 0',
  },
  p: {
    marginTop: 0,
  },
});

export const DarkWrapper = styled.div({
  background: theme.colors.black,
  padding: '20px 0',
  [tablet]: {
    padding: '30px 0',
  },
});

export const JargonLabel = styled.span({
  display: 'inline-block',
  color: theme.colors.pink,
  marginBottom: 20,
  fontWeight: theme.weights.bold,
});

export const JargonLabelMeaning = styled.span`
  font-weight: ${theme.weights.normal};
  font-style: italic;
`;

export const StyledContainer = styled(Container)({
  overflow: 'hidden',
  padding: '30px 0 60px',
  [tablet]: {
    padding: '60px 0 90px 0',
  },
});

export const StyledLogo = styled(Logo)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  width: '100%',
  height: 'auto',
  color: theme.colors.black,
});

export const TinyLogo = styled(Logo)({
  width: 92,
  height: 20,
  color: theme.colors.lightGreen,
});

export const DarkFooterRow = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  alignItems: 'center',
});

export const DarkFooterCol = styled.span({
  display: 'inline-block',
  padding: '10px 0',
});

export const DarkContainer = styled(Container)({
  position: 'relative',
});

interface FlexRowProps {
  flex?: number;
  noCollapse?: boolean;
}

export const FlexRow = styled.div<FlexRowProps>((props) =>
  props.noCollapse
    ? { display: 'flex' }
    : {
        display: 'block',
        [desktop]: {
          display: 'flex',
          justifyContent: 'space-between',
        },
      },
);

interface FlexColProps {
  width?: number;
}

export const FlexCol = styled.div<FlexColProps>((props) => ({
  padding: 20,
  '&:last-child': {
    paddingRight: 20,
  },
  [mobile]: {
    '&:first-child': {
      marginBottom: 40,
    },
  },
  [desktop]: {
    padding: '20px 40px 20px 20px',
    width: props.width,
  },
}));

export const ColTitle = styled.div({
  marginBottom: 20,
  fontWeight: theme.weights.bold,
});

export const InputRow = styled.div`
  display: flex;
  margin-top: 15px;

  button {
    white-space: nowrap;
  }
`;

export const LowerRow = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MobileButton = styled.div`
  display: none;

  ${mobile} {
    display: block;
  }
`;

export const DesktopButton = styled.div`
  display: none;
  margin-left: 10px;

  ${tablet} {
    display: block;
  }
`;

export const SocialIconsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: normal;
  flex-direction: column;

  ${mobile} {
    margin-top: 25px;
  }
`;

export const SocialIcons = styled.div`
  a {
    color: inherit;
    margin-left: 15px;

    &:first-child {
      margin-left: 0;
    }
  }
`;

export const TrustPilotIcon = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 14px;

  & .trustpilot-logo {
    margin-left: 3px;
  }
`;

export const PaymentIconsWrapper = styled.div`
  display: inline-flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  gap: 25px;

  ${mobile} {
    gap: 15px;
    margin-left: -5px;
  }
`;

export const PaymentIcon = styled.span`
  ${mobile} {
    & > svg {
      width: 40px;
    }

    &:first-child {
      margin-left: 0;
    }
  }
`;
