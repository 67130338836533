import {
  CartDataFragment,
  CreateCartDocument,
  CreateCartMutation,
  CreateCartMutationVariables,
  LineItemDataFragment,
  MyCartDocument,
  MyCartQuery,
  MyCartQueryVariables,
  UpdateCartDocument,
  UpdateCartMutation,
  UpdateCartMutationVariables,
  UpdateMyCartDocument,
  UpdateMyCartMutation,
  UpdateMyCartMutationVariables,
  useMyCartQuery,
} from 'generated/api/graphql';
import * as Sentry from '@sentry/nextjs';
import { useMemo } from 'react';
import { fallbackApolloClient, initializeApollo } from 'utils/apollo-client';
import { getStockAvailabilityFromItem } from './product';
import { useHasAnonymousToken } from './tokens/anonymousToken';
import {
  AddBillingToCartProps,
  AddedLineItem,
  AddPaymentToCartProps,
  GetGroupedCartProps,
  IChannelGroup,
  IGroupedActiveCart,
  IGroupedCart,
  RecalculateMyCartProps,
} from './types';
import { normalizeCustomFields } from 'utils/helpers';
import { useUser } from '@auth0/nextjs-auth0';

export const SPROUTL_ORDER = 'sproutl-order';
export const MARKETING_OPT_IN = 'marketingOptIn';

export async function getActiveCart(): Promise<CartDataFragment> {
  const apolloClient = initializeApollo();

  // @todo change this to use useMyCartQuery
  const myCart = await apolloClient.query<MyCartQuery, MyCartQueryVariables>({
    query: MyCartDocument,
    fetchPolicy: 'network-only',
  });

  if (myCart.data.me.activeCart) {
    return myCart.data.me.activeCart;
  }

  const createdCart = await apolloClient.mutate<
    CreateCartMutation,
    CreateCartMutationVariables
  >({
    mutation: CreateCartDocument,
  });

  if (createdCart.data?.createMyCart) {
    apolloClient.writeQuery({
      query: MyCartDocument,
      data: {
        me: {
          activeCart: createdCart.data.createMyCart,
        },
      },
    });

    return createdCart.data.createMyCart;
  }

  if (createdCart.errors) {
    Sentry.captureException(createdCart.errors);
  }

  throw new Error('Cart: Failed to getActiveCart');
}

/**
 * Clears the apollo cart cache
 */
export function clearApolloCartCache() {
  const apolloClient = initializeApollo();

  apolloClient.writeQuery({
    query: MyCartDocument,
    data: {
      me: {
        activeCart: null,
      },
    },
  });
}

export const groupItemsByChannel = (
  lineItems: LineItemDataFragment[],
): IChannelGroup[] => {
  const map = new Map();

  lineItems.forEach((lineItem) => {
    const key = lineItem.distributionChannel?.id;
    if (!map.has(key)) {
      map.set(key, {
        partner: {
          name: lineItem.distributionChannel?.name,
          slug: lineItem.distributionChannel?.key,
        },
        items: [],
      });
    }
    map.get(key).items.push(lineItem);
  });

  return [...map.values()];
};

/**
 * @param {CartDataFragment} cart
 * @returns {IGroupedCart}
 */
function groupCartLineItemsByChannel(cart: CartDataFragment): IGroupedCart {
  return {
    ...cart,
    groupedChannels: groupItemsByChannel(cart.lineItems),
    custom: {
      ...cart.custom,
      customFields: normalizeCustomFields(cart.custom),
    },
  };
}

export async function addLineItems(items: AddedLineItem[]) {
  const apolloClient = initializeApollo();
  const myCart = await getActiveCart();

  try {
    return await apolloClient.mutate<
      UpdateMyCartMutation,
      UpdateMyCartMutationVariables
    >({
      mutation: UpdateMyCartDocument,
      variables: {
        id: myCart.id,
        version: myCart.version,
        actions: items.map((item) => ({
          addLineItem: {
            sku: item.sku,
            quantity: item.quantity,
            supplyChannel: { id: item.channel.id },
            distributionChannel: { id: item.channel.id },
          },
        })),
      },
    });
  } catch (e) {
    Sentry.captureException(e);
    throw new Error(`Basket: Failed to add item to basket. ${e?.message}`);
  }
}

/**
 * Get GroupedActiveCart in server side operations
 * @async
 * @param {GetGroupedCartProps} props
 * @returns {Promise<IGroupedCart | null>}
 */
export async function getGroupedCart({
  token,
  client,
}: GetGroupedCartProps): Promise<IGroupedCart | null> {
  const apolloClient = fallbackApolloClient(client);

  const { data } = await apolloClient.query<MyCartQuery, MyCartQueryVariables>({
    query: MyCartDocument,
    context: {
      token,
    },
  });

  const { activeCart } = data.me;

  return activeCart ? groupCartLineItemsByChannel(activeCart) : null;
}

/**
 * React hook to get the GroupedActiveCart
 * @returns {GroupedActiveCart}
 */
export function useGroupedActiveCart(): IGroupedActiveCart {
  const hasAnonymousToken = useHasAnonymousToken();
  const hasUserToken = !!useUser().user;

  const hasToken = hasAnonymousToken || hasUserToken;

  const { data, loading, refetch } = useMyCartQuery({
    skip: !hasToken,
    onError: (err) => {
      Sentry.captureException(err);
    },
  });

  const groupedCart = useMemo(
    () =>
      data?.me.activeCart
        ? groupCartLineItemsByChannel(data.me.activeCart)
        : null,
    [data],
  );

  return { data: groupedCart, loading, refetch };
}

/**
 * Add an existing payment to an existing cart
 * @async
 * @param {AddPaymentToCartProps} props
 * @returns {Promise<IGroupedActiveCart>}
 */
export async function addPaymentToCart({
  data,
  token,
  client,
}: AddPaymentToCartProps): Promise<IGroupedActiveCart> {
  const apolloClient = fallbackApolloClient(client);

  const { cartId, paymentId, cartVersion } = data;

  const result = await apolloClient.mutate<
    UpdateCartMutation,
    UpdateCartMutationVariables
  >({
    mutation: UpdateCartDocument,
    variables: {
      actions: {
        addPayment: {
          payment: {
            typeId: 'payment',
            id: paymentId,
          },
        },
      },
      id: cartId,
      version: cartVersion,
    },
    context: {
      token,
    },
  });

  if (result.data?.updateCart) {
    return { data: groupCartLineItemsByChannel(result.data.updateCart) };
  }

  if (result.errors) {
    Sentry.captureException(result.errors);
  }

  throw new Error('Cart: Failed to addPaymentToCart');
}

export function isCartInStock(cart: CartDataFragment | null): boolean {
  if (!cart) {
    return false;
  }

  return cart.lineItems.every((item) => {
    const { isOnStock, availableQuantity } = getStockAvailabilityFromItem(item);
    return isOnStock && item.quantity <= availableQuantity;
  });
}

/**
 * Trigger the cart update recalculate action
 * @async
 * @param {RecalculateMyCartProps} props
 * @returns {Promise<IGroupedActiveCart>}
 */
export async function recalculateMyCart({
  data,
  token,
  client,
}: RecalculateMyCartProps): Promise<IGroupedActiveCart> {
  const apolloClient = fallbackApolloClient(client);

  const { cartId, cartVersion } = data;

  const result = await apolloClient.mutate<
    UpdateMyCartMutation,
    UpdateMyCartMutationVariables
  >({
    mutation: UpdateMyCartDocument,
    variables: {
      actions: {
        recalculate: {},
      },
      id: cartId,
      version: cartVersion,
    },
    context: {
      token,
    },
  });

  if (result.data?.updateMyCart) {
    return { data: groupCartLineItemsByChannel(result.data.updateMyCart) };
  }

  if (result.errors) {
    Sentry.captureException(result.errors);
  }

  throw new Error('Cart: Failed to recalculateMyCart');
}

/**
 * Add billing address to cart and trigger recalculate action
 * @async
 * @param {AddBillingToCartProps} props
 * @returns {Promise<CartDataFragment>}
 */
export async function addBillingToCart({
  data,
  token,
  client,
}: AddBillingToCartProps): Promise<CartDataFragment> {
  const apolloClient = fallbackApolloClient(client);

  const { cartId, cartVersion, billingAddress } = data;

  const result = await apolloClient.mutate<
    UpdateCartMutation,
    UpdateCartMutationVariables
  >({
    mutation: UpdateCartDocument,
    variables: {
      actions: [
        {
          setBillingAddress: {
            address: billingAddress,
          },
        },
        {
          recalculate: {},
        },
      ],
      id: cartId,
      version: cartVersion,
    },
    context: {
      token,
    },
  });

  if (result.data?.updateCart) {
    return result.data.updateCart;
  }

  if (result.errors) {
    Sentry.captureException(result.errors);
  }

  throw new Error('Cart: Failed to addBillingRecalculateCart');
}
